var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col flex-1"},[(_vm.showModal)?_c('ValidateModal',{on:{"isCancel":_vm.continuer,"isValide":_vm.quitter}},[_vm._t("default",[_vm._v(" Si vous quittez maintenant le questionnaire, vos données ne seront pas sauvegardées. ")],{"slot":"message_body"}),_vm._t("default",[_vm._v("Quitter")],{"slot":"button_valide"}),_vm._t("default",[_vm._v("Continuer")],{"slot":"button_cancel"})],2):_vm._e(),_c('section',{staticClass:"header relative flex flex-1"},[_c('form-wizard',{ref:"formWizard",attrs:{"form":_vm.form,"steps":_vm.steps},on:{"is_finished":function () {
          _vm.is_finished = true
        },"setCurrentStep":function (current) {
          _vm.currentStep = current
        }}},_vm._l((_vm.steps),function(step,index){return _c('step',{key:index,ref:"tunnelStep",refInFor:true,attrs:{"selected":step.selected},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var isActive = ref.isActive;
        var invalid = ref.invalid;
return _c(step.component,{tag:"component",attrs:{"cadastre":_vm.cadastre,"isActive":isActive,"invalid":invalid,"form":_vm.form}})}}],null,true)})}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }