<template>
  <LayoutTunnel>
    <template slot="left-side">
      <div
        class="flex flex-col flex-1 items-start self-center justify-around px-16 lg:px-8 max-w-xl xl:w-144"
      >
        <div></div>
        <div class="flex flex-col w-full">
          <div
            class="text-dark-grey-400 font-poppins font-medium text-3xl mb-3"
          >
            Il s’agit d’une résidence...
          </div>

          <div>
            <validation-provider
              class="relative flex flex-col"
              rules="required"
              v-slot="{ errors }"
            >
              <div
                class="grid gap-2"
                style="grid-template-columns: repeat(auto-fill, 110px)"
              >
                <selectable-button
                  v-for="(residence, index) in residenceSelect"
                  :key="residence.name + index"
                  v-model="form.informations.residence"
                  :option-name="residence.name"
                >
                  <template #icon>
                    <component
                      :is="residence.icon"
                      :fill="
                        form.informations.residence === residence.name
                          ? 'white'
                          : 'blue-ice'
                      "
                    />
                  </template>
                </selectable-button>
              </div>
              <span
                v-show="errors[0]"
                class="mt-1 text-xs text-promy-red-400"
                >{{ errors[0] }}</span
              >
            </validation-provider>
          </div>
        </div>

        <portal-target class="sm:w-full" name="next-prev-step"> </portal-target>
      </div>
    </template>
    <template slot="right-side">
      <Map
        :isActive="isActive"
        :showBtnParcelle="true"
        v-if="isActive"
        :form="form"
        :cadastre="cadastre"
      />
    </template>
  </LayoutTunnel>
</template>
<script>
import Map from '../Map'

export default {
  components: { Map },
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
      required: true,
    },
    cadastre: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      residenceSelect: [
        { name: 'Principale', icon: 'PrimaryIcon' },
        { name: 'Secondaire', icon: 'SecondaryIcon' },
      ],
    }
  },
}
</script>
