<template>
  <div v-show="isActive" class="flex flex-1">
    <ValidationObserver
      class="w-full flex flex-1"
      v-slot="{ invalid, passes }"
      ref="validationObserver"
    >
      <slot :isActive="isActive" :invalid="invalid"></slot>
    </ValidationObserver>
  </div>
</template>

<script>
export default {
  name: 'tab',
  props: {
    selected: { default: false },
  },
  data() {
    return {
      isActive: false,
      test: 'ddd',
    }
  },
  created() {
    this.isActive = this.selected
  },
}
</script>
