<template>
  <LayoutTunnel>
    <template slot="left-side">
      <div
        class="flex flex-col flex-1 items-start self-center justify-around pl-16 lg:px-8 max-w-xl"
      >
        <div></div>
        <div class="flex flex-col space-y-6">
          <div>
            <div
              class="text-dark-grey-400 font-medium font-poppins text-3xl sm:text-2xl mb-3"
            >
              Souhaitez-vous vendre votre bien ?
            </div>

            <div>
              <validation-provider
                class="relative flex flex-col"
                rules="required"
                v-slot="{ errors }"
              >
                <div class="flex flex-wrap space-right">
                  <selectable-button
                    v-for="(solde, index) in soldeList"
                    :key="solde + index"
                    v-model="form.informations.on_sale"
                    :option-name="solde"
                  />
                </div>
                <span
                  v-show="errors[0]"
                  class="mt-1 text-xs text-promy-red-400"
                  >{{ errors[0] }}</span
                >
              </validation-provider>
            </div>
          </div>
          <div>
            <div
              class="text-dark-grey-400 font-medium font-poppins text-3xl sm:text-2xl mb-3"
            >
              Avez-vous déjà fait estimer ce bien ?
            </div>
            <validation-provider
              class="flex flex-col mb-4"
              name="form.informations.is_estimated"
              rules="required"
              v-slot="{ errors }"
            >
              <div class="flex gap-4">
                <RadioInput
                  :id="_uid + 'oui'"
                  label="oui"
                  :value="form.informations.is_estimated"
                  @change="change($event)"
                >
                  Oui
                </RadioInput>
                <RadioInput
                  :id="_uid + 'non'"
                  label="non"
                  :value="form.informations.is_estimated"
                  @change="change($event)"
                >
                  Non
                </RadioInput>
              </div>
              <div class="w-full">
                <span
                  v-show="errors[0]"
                  class="mt-1 text-xs text-promy-red-400"
                  >{{ errors[0] }}</span
                >
              </div>
            </validation-provider>
          </div>
        </div>
        <portal-target class="sm:w-full sm:py-2 sm:mt-2" name="next-prev-step">
        </portal-target>
      </div>
    </template>
    <template slot="right-side">
      <Map
        :isActive="isActive"
        v-if="isActive"
        :form="form"
        :cadastre="cadastre"
      />
    </template>
  </LayoutTunnel>
</template>
<script>
import Map from '../Map'

export default {
  components: { Map },
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
      required: true,
    },
    cadastre: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      soldeList: [
        'Oui, dès que possible',
        'Oui, d’ici 3 mois',
        'Oui, d’ici 6 mois',
        'Oui, dans plus de 6 mois',
        'Non, je souhaite juste me renseigner',
      ],
    }
  },
  methods: {
    change(value) {
      this.form.informations.is_estimated = value
    },
  },
}
</script>

<style lang="scss" scoped>
.space-right > button {
  @apply mr-2 mb-2;
}
</style>
