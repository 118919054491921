<template>
  <LayoutTunnel>
    <template slot="left-side">
      <div
        class="flex flex-col flex-1 items-start self-center justify-around pl-16 lg:px-8 max-w-xl"
      >
        <div></div>
        <div class="flex flex-col space-y-6">
          <div
            class="text-dark-grey-400 font-medium font-poppins text-3xl sm:text-2xl"
          >
            Apprenons à nous connaître. Qui êtes vous ?
          </div>
          <div class="w-full pb-8">
            <div>
              <validation-provider
                class="relative flex flex-col"
                rules="required"
                v-slot="{ errors }"
              >
                <div
                  class="grid gap-2"
                  style="grid-template-columns: repeat(auto-fill, 110px)"
                >
                  <selectable-button
                    v-for="(type, index) in userTypes"
                    :key="type.name + index"
                    v-model="form.informations.label_profile"
                    :option-name="type.name"
                  >
                    <template #icon>
                      <component
                        :is="type.icon"
                        :fill="
                          form.informations.label_profile === type.name
                            ? 'white'
                            : 'blue-ice'
                        "
                      />
                    </template>
                  </selectable-button>
                </div>
                <span
                  v-show="errors[0]"
                  class="mt-1 text-xs text-promy-red-400"
                  >{{ errors[0] }}</span
                >
              </validation-provider>
            </div>
          </div>
          <ProAlertHint
            class="hidden"
            v-if="form.informations.label_profile === 'professionnel'"
          >
            <template>
              <div
                class="w-3/4 sm:w-full text-promy-gray-700 mb-4 font-poppins"
              >
                En tant qu’expert de l’immobilier, vous bénéficiez d’un accés à
                notre platforme professionnelle.
              </div>
              <router-link
                to="#"
                class="text-promy-purple-300 font-extrabold font-poppins"
                >Demandez l’accés</router-link
              >
            </template>
          </ProAlertHint>
        </div>
        <portal-target class="sm:w-full sm:py-2 sm:mt-2" name="next-prev-step">
        </portal-target>
      </div>
    </template>
    <template slot="right-side">
      <Map
        :isActive="isActive"
        v-if="isActive"
        :form="form"
        :cadastre="cadastre"
      />
    </template>
  </LayoutTunnel>
</template>
<script>
import Map from '../Map'

export default {
  components: { Map },
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
      required: true,
    },
    cadastre: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      userTypes: [
        {
          name: 'propriétaire',
          icon: 'OwnerIcon',
        },
        {
          name: 'un proche',
          icon: 'RelatedIcon',
        },
        {
          name: 'locataire',
          icon: 'TenantIcon',
        },
        {
          name: 'professionnel',
          icon: 'ProfessionalIcon',
        },
      ],
    }
  },
  created() {
    this.form.informations.label_profile = 'propriétaire'
  },
  methods: {
    change(value) {
      this.form.informations.label_profile = value
    },
  },
}
</script>
