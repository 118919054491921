<template>
  <div class="flex flex-col flex-1">
    <ValidateModal v-if="showModal" @isCancel="continuer" @isValide="quitter">
      <slot slot="message_body">
        Si vous quittez maintenant le questionnaire, vos données ne seront pas sauvegardées.
      </slot>
      <slot slot="button_valide">Quitter</slot>
      <slot slot="button_cancel">Continuer</slot>
    </ValidateModal>
    <section class="header relative flex flex-1">
      <form-wizard
        @is_finished="
          () => {
            is_finished = true
          }
        "
        :form="form"
        @setCurrentStep="
          (current) => {
            currentStep = current
          }
        "
        ref="formWizard"
        :steps="steps"
      >
        <step
          v-for="(step, index) in steps"
          :key="index"
          :selected="step.selected"
          ref="tunnelStep"
        >
          <component
            :cadastre="cadastre"
            slot-scope="{ isActive, invalid }"
            :is="step.component"
            :isActive="isActive"
            :invalid="invalid"
            :form="form"
          ></component>
        </step>
      </form-wizard>
    </section>
  </div>
</template>
<script>
import formWizard from './formWizard'
import step from './step'
import step1 from './Steps/step1'
import step2 from './Steps/step2'
import step3 from './Steps/step3'
import step4 from './Steps/step4'
import step5 from './Steps/step5'
import step6 from './Steps/step6'
import step7 from './Steps/step7'
import step8 from './Steps/step8'

export default {
  components: {
    formWizard,
    step,
    step1,
    step2,
    step3,
    step4,
    step5,
    step6,
    step7,
    step8,
  },
  data() {
    return {
      next_route: '',
      showModal: false,
      is_finished: false,
      cadastre: {
        old_adresse: null,
        is_api_loading: true,
        data: {},
        is_parcelle_changed: false,
        is_parcelle_aggregate: false,
        parcelle_autour: [],
        features_autour: [],
        error_parcelle_not_found: null,
        is_adresse_changed: false,
      },
      form: {
        civilite: null,
        nom: null,
        prenom: null,
        email: null,
        tel: null,
        informations: {
          refsCadastrale: [],
          current_parcelle: null,
          parcelles_voisines: [],
          batiments: [],
          coords_parcelle_principale: [],
          adresse: null,
          residence: null,
          superficie_totale: null,
          superficie_construite: null,
          pieces: null,
          etat_bien: null,
          label_profile: null,
          on_sale: null,
          is_estimated: null,
          more_informations: null,
          code_insee: null,
          code_postal: null,
          departement: null,
          region: null,
        },
      },
      totalSteps: 0,
      currentStep: 0,
      steps: [
        {
          component: 'step1',
          name: 'Step 1',
          selected: true,
          route: '/adresse',
        },

        {
          component: 'step2',
          name: 'Step 2',
          selected: false,
          route: '/residence',
        },
        {
          component: 'step3',
          name: 'Step 3',
          selected: false,
          route: '/superficies',
        },
        {
          component: 'step4',
          name: 'Step 4',
          selected: false,
          route: '/profile',
        },
        {
          component: 'step5',
          name: 'Step 5',
          selected: false,
          route: '/vendre-bien',
        },
        {
          component: 'step6',
          name: 'Step 6',
          selected: false,
          route: '/informations-complementaire',
        },
        {
          component: 'step7',
          name: 'Step 7',
          selected: false,
          route: '/cherche-offres',
        },
        {
          component: 'step8',
          name: 'Step 8',
          selected: false,
          route: '/informations-personnelles',
        },
      ],
    }
  },
  created() {
    this.form = _.merge(
      {},
      this.form,
      JSON.parse(localStorage.getItem('form_tunnel')),
    )
  },

  watch: {
    form: {
      deep: true,
      handler(newForm) {
        if (newForm.informations.adresse) {
          let form = JSON.parse(JSON.stringify(newForm))
          localStorage.setItem('form_tunnel', JSON.stringify(form))
        }
      },
    },
  },
  methods: {
    continuer() {
      this.showModal = false
    },
    quitter() {
      this.showModal = false
      this.is_finished = true
      localStorage.removeItem('form_tunnel')
      this.$router.push(this.next_route)
    },
  },

  beforeRouteLeave(to, from, next) {
    if (!this.is_finished) {
      this.next_route = to.path
      this.showModal = true
    } else next()
  },
}
</script>
